.layout-container {
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
.nav-cild {
  width: 80%;
}
.sidebar-boxxx {
  width: 20%;
  background: #1a1c1e;
  min-height: 100vh;
}
.sidebar-container {
  background: #1a1c1e;
  width: 30%;
  min-height: 100vh;
  margin-top: 0;
  padding-top: 10px;
  z-index: 11;
}
.logo-side {
  margin: 10px 0 20px;
}
.logo-side img {
  width: 50px;
}
.sidebar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  /* left: 3%; */
}

.ctrl-btn {
  width: 40px;
  height: 40px;
  background: #1a1c1e;
  position: fixed;
  font-size: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #fff;
  left: 15%;
  z-index: 3;
  top: 14%;
  display: none;
}
.side-item {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: #fff;
  gap: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  padding-left: 40px;
  height: 40px;
  width: 290px;
}
.side-icon {
  font-size: 23px;
}
.active_link {
  color: #fff;
  background: #0066f5;
}
li {
  list-style: none;
}
.lout {
  margin-top: 10rem;
  cursor: pointer;
  color: #fff;
}

/* Navbar */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 1;
  border-bottom: 1px solid #e8e8e8;
}
.logo-container {
  display: flex;
  align-items: center;
  gap: 0px;
  font-size: 20px;
  font-weight: 500;
  color: #303030;
  flex-direction: column;
}
.logo-container img {
  width: 60px;
}
.total-user-txt {
  font-size: 12px;
  color: #0066f5;
  font-weight: 600;
}
.logo-img {
  display: none;
}
.searc-profile {
  display: flex;
  align-items: center;
  gap: 15px;
}
.searc-container {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 35px;
  width: 400px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
}
.searc-inp {
  border: none;
  outline: none;
  width: 80%;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
}
.sea-icon {
  font-size: 20px;
  color: #0000009f;
}
.profile-container {
  font-size: 12px;
  color: #303030;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;
}
.profile-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.mobile-searc {
  display: none;
}
.notif-cont {
  position: relative;
  cursor: pointer;
}
.bell {
  font-size: 30px;
}
.bell-count {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff1d1d;
  font-size: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12%;
  top: 16%;
}
.name-down {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
}
.adm-tst {
  color: #7c7c7c;
}
.dww {
  font-size: 20px;
}
.manage-admin {
  color: #fff;
  gap: 5px;
  border-radius: 4px;
  background: #082552;
  height: 50px;
  cursor: pointer;
  justify-content: center;
  width: 80%;
  margin: 50px 10px;
}
.mana-text {
  font-size: 18px;
}
.mana-icon {
  font-size: 25px;
}
@media only screen and (max-width: 1300px) {
  .sidebar-container {
    background: #1a1c1e;
    width: 20%;
    min-height: 100vh;
    margin-top: 0;
    position: fixed;
    z-index: 11;
  }
  .side-item {
    width: 230px;
  }
}
@media only screen and (max-width: 1150px) {
  .sidebar-container {
    background: #1a1c1e;
    width: 25%;
    min-height: 100vh;
    margin-top: 0;
    position: fixed;
    z-index: 11;
  }
  .logo-img {
    display: initial;
  }
  .side-item {
    width: 280px;
  }

  .nil {
    display: none;
  }
  .ctrl-btn {
    display: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    left: 23%;
    z-index: 111;
  }
  .lst {
    left: 0;
    top: 15%;
  }
  .lout {
    margin-top: 6rem;
    cursor: pointer;
    color: #fff;
  }
  .navbar-container {
    width: 100%;
    padding: 10px 80px;
  }
  .sidebar-boxxx {
    width: 0;
  }
}
@media only screen and (max-width: 1120px) {
  .side-item {
    width: 260px;
  }
}
@media only screen and (max-width: 1040px) {
  .side-item {
    width: 250px;
  }
}
@media only screen and (max-width: 1000px) {
  .side-item {
    width: 235px;
  }
}

@media only screen and (max-width: 940px) {
  .active_link {
    background: none;
    color: #0066f5;
  }
  .side-item {
    padding-left: 10px;
    height: 20px;
    margin-bottom: 15px;
  }
  .mobile-searc {
    display: initial;
  }
  .mobile-searc .sea-icon {
    font-size: 25px;
  }
  .searc-container {
    position: absolute;
    top: 100%;
    right: 10%;
    background: #fff;
  }
  .lst {
    left: 0;
    top: 15%;
  }
}
@media only screen and (max-width: 750px) {
  .sidebar-container {
    width: 30%;
  }
  .ctrl-btn {
    left: 28%;
  }
  .lst {
    left: 0;
  }
}
@media only screen and (max-width: 700px) {
  .navbar-container {
    padding: 10px 50px;
  }
}
@media only screen and (max-height: 600px) {
  .side-item {
    margin-bottom: 10px;
  }
  .lout {
    margin-top: 3rem;
  }
}
@media only screen and (max-height: 500px) {
  .side-item {
    margin-bottom: 8px;
  }
  .lout {
    margin-top: 2rem;
  }
}
@media only screen and (max-height: 425px) {
  .sidebar-container {
    padding-top: 5px;
  }
  .side-item {
    margin-bottom: 10px;
  }
  .lout {
    margin-top: 0rem;
  }
}
@media only screen and (max-height: 355px) {
  .sidebar-container {
    padding-top: 2px;
  }
  .side-item {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .side-icon {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .sidebar-container {
    width: 35%;
  }
  .ctrl-btn {
    left: 32%;
  }
  .lst {
    left: 0;
  }
}
@media only screen and (max-width: 500px) {
  .sidebar-container {
    width: 40%;
  }
  .ctrl-btn {
    left: 37%;
  }
  .lst {
    left: 0;
  }
  .navbar-container {
    padding: 10px 30px;
  }
  .logo-container {
    font-size: 20px;
  }
  .logo-container img {
    width: 50px;
  }
  .profile-container img {
    width: 40px;
    height: 40px;
  }
  .searc-container {
    width: 75%;
  }
  .bell {
    font-size: 27px;
  }

  .logo-text {
    display: none;
  }
  .pro-text {
    display: none;
  }
  .adm-tst {
    display: none;
  }
}
@media only screen and (max-width: 420px) {
  .side-item {
    font-size: 14px;
  }
  .navbar-container {
    padding: 10px 20px;
  }
  .sidebar-container {
    width: 50%;
  }
  .ctrl-btn {
    left: 45%;
  }
  .lst {
    left: 0;
  }
}
@media only screen and (max-width: 340px) {
  .sidebar-container {
    width: 58%;
  }
  .ctrl-btn {
    left: 50%;
  }
  .lst {
    left: 0;
  }
}
