.hero-section-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: space-between;
  overflow-x: hidden;
}
.landing-page-container {
  padding-top: 8em;
}
.left-side-hero {
  /* width: 50%; */
  padding-left: 80px;
}
.right-side-containe {
  /* width: 50%; */
  margin-right: -50px;
  /* background-color: green; */
}
.main-hero-head {
  /* font-size: 43px; */
  font-weight: 700;
  /* line-height: 60px; */
  color: #282828;
}
.main-hero-head span {
  color: #0066f5;
}
.head-line-box {
  width: 96%;
  /* background-color: red; */
}
.hero-simple {
  font-size: 20px;
  margin: 20px 0;
  color: #686868;
}
.app-badge-box img {
  width: 90%;
  height: 80px;
  cursor: pointer;
}
.app-badge-box {
  display: flex;
  gap: 20px;
  margin-top: 80px;
}
.right-side-containe img {
  padding-top: 20px;
  /* transform: translate(20%, 0); */
  /* width: 40%; */
}

.land-lay {
  background: rgba(66, 66, 66, 0.242);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 111115;
  justify-content: center;
}

.rght {
  justify-content: right;
  padding: 10px 20px;
  gap: 30px;
}
@media only screen and (max-width: 1150px) {
  .head-line-box {
    width: 85%;
  }
  .main-hero-head {
    /* font-size: 38px; */
    /* line-height: 35px; */
  }
  .app-badge-box img {
    width: 50%;
    height: 50px;
  }
  .right-side-containe {
    /* width: 45%; */
    margin-right: 0px;
  }
}
@media only screen and (max-width: 940px) {
  .head-line-box {
    width: 100%;
  }
  .main-hero-head {
    /* font-size: 34px; */
    /* line-height: 40px; */
  }
  .app-badge-box {
    gap: 10px;
    margin-top: 50px;
  }
  .hero-simple {
    font-size: 14px;
  }

}
@media only screen and (max-width: 750px) {
  .main-hero-head {
    /* font-size: 34px; */
    /* line-height: 40px; */
  }
  .app-badge-box {
    gap: 10px;
    margin-top: 50px;
  }
  .hero-simple {
    font-size: 14px;
  }
  .left-side-hero {
    padding-left: 20px;
  }

  .hero-section-container {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 655px) {
  .main-hero-head {
    /* font-size: 25px; */
    /* line-height: 30px; */
  }
  .app-badge-box {
    gap: 10px;
    margin-top: 30px;
  }
  .hero-simple {
    font-size: 12px;
    margin: 5px 0;
  }
  .app-badge-box img {
    width: 50%;
    height: 30px;
  }

}

@media only screen and (max-width: 500px) {
  .main-hero-head {
    /* font-size: 18px; */
    /* line-height: 25px; */
  }
  .app-badge-box {
    gap: 5px;
    margin-top: 20px;
  }
  .hero-simple {
    font-size: 13px;
  }
  .app-badge-box img {
    width: 45%;
  }
  .left-side-hero {
    padding-left: 0px;
  }

  .hero-section-container {
    padding-left: 25px;
  }

}
@media only screen and (max-width: 370px) {
  .main-hero-head {
    /* font-size: 14px; */
    /* line-height: /20px; */
  }
  .hero-simple {
    font-size: 13px;
  }
  .app-badge-box {
    margin-top: 10px;
  }
  .app-badge-box img {
    width: 50%;
    height: 30px;
  }
}
