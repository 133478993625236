/* ?login */
.admin-login-container {
  max-height: 100vh;
}
.tit-top {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-top: 40px;
}
.adm-inp {
  width: 100%;
  height: 40px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  margin: 10px 0;
  outline: none;
  padding: 0 15px;
  font-size: 15px;
}
.log-bbtn {
  width: 30%;
  height: 35px;
  outline: none;
  border-radius: 5px;
  background: #0066f5;
  border: none;
  margin: 20px auto;
  font-size: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.log-bx-cont {
  width: 50%;
  margin: 15em auto;
  border: 2px solid #0066f5;
  padding: 60px 20px;
  border-radius: 0 40px 0 40px;
}
.eye-icon {
  font-size: 20px;
  position: absolute;
  top: 35%;
  right: 20px;
  cursor: pointer;
}
.pass-con {
  position: relative;
}
.mess {
  font-size: 20px;
  text-align: center;
  color: red;
}
.admin-top {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: -140px;
}
.small-ad-tst {
  font-size: 16px;
  font-weight: 400;
}
.cls-sap {
  font-size: 25px;
  cursor: pointer;
}
.ead-box-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
