/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

html {
  font-size: 62.5%;
  height: 100%;
}
#root {
  height: 100%;
  width: 100%;
}
body {
  width: 100%;
  height: 100%;
  background: var(--background-main-color);
}
:root {
  --background-main-color: #fff;
  --primary: #d23369;
  --primary-deep: #f06292;
}
