.header {
  position: fixed;
  z-index: 100001;
  margin-bottom: 80px;
  width: 100%;
}
.navbar {
  position: fixed;
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  top: 0;
  right: 0;
  left: 0;
  padding: 40px 0;
}
.navbar_container {
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  padding: 0 60px;
  flex: 1;
}
.active-link {
  /* color: #0066f5; */
  text-decoration: none;
  font-weight: bold;
}
.logo {
  color: #0066f5;
  cursor: pointer;
}
.logo img {
  width: 70px;
}
#ul {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 4rem;
  gap: 50px;
}
#ul li {
  line-height: 40px;
  margin-right: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}
.navbar_links {
  color: #282828;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.nav-login {
  background: #0066f5;
  width: 200px;
  height: 40px;
  text-align: center;
  padding: 7px 0;
  margin-right: 8rem;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-login .login-up {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.nav-icon {
  display: none;
}
.nnon {
  display: none;
}
/* footer */
.footer-container {
  background: #1f2124;
  margin-top: 60px;
  color: #fff;
  padding: 40px 80px;
}
.footer-logo-bx {
  width: 30%;
}
.footer-logo-bx img {
  width: 100px;
  height: 50px;
}
.badge-app-cont img {
  width: 30%;
  height: 40px;
  margin-right: 10px;
  margin-top: 15px;
  cursor: pointer;
}
.txt-foot {
  font-size: 15px;
  cursor: pointer;
  line-height: 25px;
  margin-bottom: 10px;
}
.footer-main-container {
  display: flex;
  justify-content: space-between;
}
.the-all-footer-txt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
}
.line-foot {
  border: 1px solid #535353;
  margin: 30px 0;
}
.pol-copy {
  color: #686868;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.social-mdia {
  font-size: 24px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
  cursor: pointer;
}
.social-mdia img {
  width: 30px;
  height: 30px;
}
.face {
  color: #0066f5;
}
.instam {
  color: rgba(255, 84, 62, 1);
}
.tube {
  color: rgba(255, 0, 0, 1);
}
.lin {
  color: rgba(0, 118, 178, 1);
}
.social-mdia a {
  color: #fff;
}

@media only screen and (max-width: 1150px) {
  #ul {
    margin-right: 01rem;
    gap: 20px;
  }
  #ul li {
    line-height: 20px;
    margin-right: 7px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 1000px) {
  #ul li {
    margin-right: 4px;
  }
  .nav-login {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 940px) {
  .navbar {
    padding: 40px;
  }
  .navbar_container {
    padding: 0 20px;
  }
  #ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    position: absolute;
    top: 0;
    right: 110%;
    opacity: 1;
    transition: all 0.5s ease;
    height: 100vh;
    padding-top: 90px;
    align-items: flex-start;
    padding-left: 15px;
  }
  #ul li {
    width: 100%;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  /* .ludrop  {
    width: 50%;
    color: #d82121;
    background-color: #9e0909;
  }
  .ludrop >.lidrop {
    color: #d82121;
  } */
  .nav-icon {
    display: block;
    cursor: pointer;
    padding-right: 20px;
    z-index: 1113;
    color: #fff;
  }

  .bars {
    color: #0066f5;
  }
  .active {
    background: #0066f5;
    left: 65%;
    opacity: 1;
    z-index: 999;
  }
  .navbar_links {
    color: #fff;
  }
  .active-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  .logo img {
    width: 60px;
  }
  .nnon {
    display: initial;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 10px;
  }
  .nnon .login-up {
    color: #0066f5;
  }

  .nnil {
    display: none;
  }
  .footer-container {
    padding: 30px 40px;
  }
  .footer-logo-bx {
    width: 30%;
  }

  .badge-app-cont img {
    width: 40%;
    height: 30px;
  }
  .txt-foot {
    font-size: 13px;
  }
  .the-all-footer-txt {
    width: 65%;
  }
  .header {
    /* padding: 0 20px; */
  }
  .social-mdia {
    width: 100px;
  }
  .social-mdia img {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .footer-main-container {
    flex-direction: column;
    gap: 20px;
  }
  .footer-logo-bx {
    width: 50%;
  }
  .the-all-footer-txt {
    width: 100%;
  }
  .active {
    background: #0066f5;
    left: 50%;
    opacity: 1;
    z-index: 999;
  }
}
@media only screen and (max-width: 500px) {
  .nav-login {
    width: 100%;
    height: 35px;
  }
  .login-up {
    font-size: 15px;
  }
  .footer-logo-bx {
    width: 80%;
  }
  .one-mg {
    width: 40px;
  }
  .txt-foot {
    font-size: 11px;
    line-height: 20px;
  }
  .footer-container {
    padding: 10px;
  }
  .line-foot {
    margin: 10px 0;
  }
  .pol-copy {
    font-size: 11px;
  }
}
@media only screen and (max-width: 350px) {
  #ul {
    width: 70%;
  }
  #ul li {
    font-size: 14px;
  }
  .active {
    left: 35%;
  }
  .login-up {
    font-size: 13px;
    padding: 5px;
  }
  .navbar {
    padding: 20px;
  }
  .nav-login {
    width: 100%;
  }
  .logo img {
    width: 50px;
  }
  .each-col-cont {
    margin-bottom: 15px;
  }
  .txt-foot {
    font-size: 13px;
  }
  .the-all-footer-txt {
    flex-wrap: wrap;
  }
  .social-mdia {
    width: 100%;
  }
}
