.add-pre-btn {
  font-size: 14px;
  color: #7c7c7c;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  width: 140px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
}
.add-pre-btn:hover {
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background: #d9d9d9;
}

.not-found-message {
  margin: 20px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  right: 35%;
  justify-content: center;
  position: absolute;
}
.sear-inp {
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-right: 5px;
}
.searc-bx {
  color: #7c7c7c;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  font-size: 20px;
  height: 30px;
  padding: 0 5px;
  width: 70%;
}
.sel-filter {
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  height: 30px;
  outline: none;
  min-width: 30%;
}
.filter-search {
  gap: 10px;
  width: 50%;
}
.customer-page-toper {
  justify-content: space-between;
}
.tabl-containe table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
  color: #303030;
  min-height: 120px;
}

.tabl-containe th,
.tabl-containe td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eeeeee;
}
.tb-row {
  position: relative;
}
.actt {
  border-radius: 3px;
  background: #34a8532e;
  color: #34a853;
  height: 25px !important;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.action-men {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  cursor: pointer;
  font-size: 20px;
}
.susp {
  background: #ff1d1d29;
  color: #ff1d1d;
}
.tabl-containe {
  border-radius: 12px;
  border: 1px solid #eee;
  background: #fff;
  margin-top: 26px;
  padding: 0 15px;
}
.show-counter {
  color: #8e8e8e;
  font-size: 12px;
}
.pagin-sow-cont {
  justify-content: space-between;
  margin: 14px 0 0;
}
.main-modal-overlay {
  background: rgba(66, 66, 66, 0.082);
  width: 100%;
  height: 100vh;
  z-index: 1133;
  position: fixed;
}
