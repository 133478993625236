.form-cont {
  width: 50%;
  margin: 0px auto;
  font-size: 15px;
  padding-top: 90px;
}
.form-cont input,
.form-cont textarea {
  outline: none;
}

@media only screen and (max-width: 800px) {
  .form-cont {
    width: 90%;
    padding-top: 100px;
  }
}
