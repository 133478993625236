.chart-top-cus-row {
  display: flex;
  margin: 20px 0;
  gap: 40px;
}
.chart-container {
  position: relative;
  width: 65%;
}

.apexcharts-legend {
  position: absolute;
  top: -10px;
  left: 0;
  background: #fff;
  border-radius: 5px;
}
.right-side-cont {
  width: 35%;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 15px;
  height: 430px;
}
.bbb {
  border: 1px solid #e8e8e8;
  color: #898989;
  width: 80px;
  height: 25px;
  outline: none;
}
.select-buttons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 1%;
  right: 6%;
  z-index: 123;
}
.head-controller-bx {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
.navigator-box {
  display: flex;
  gap: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 940px) {
  .chart-top-cus-row {
    flex-direction: column;
  }
  .chart-container {
    width: 100%;
  }
  .right-side-cont {
    width: 100%;
  }
}
