.our-feature-container {
  margin: 50px 0;
}
.head-tit {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #383838;
}
.rec-head {
  width: 200px;
  height: 15px;
  background: linear-gradient(180deg, rgba(0, 102, 245, 0) 0%, #0066f5 223.53%);
  margin-top: -12px;
}
.our-feature-head {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.become-ag-cus-container {
  border-radius: 35px;
  background: #f9f9f9;
  height: 45px;
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: space-between;
  padding: 5px 15px;
}
.become-ae-cus-bx {
  color: #686868;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.act-tab {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #383838;
}
.agent-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}
.inst-works {
  font-size: 14px;
  margin: 20px 0 40px;
  color: #686868;
}
.famh {
  width: 35%;
}
.works {
  justify-content: center;
}
.famh img {
  /* height: 50%; */
  width: 200px;
}
.samm {
  width: 20%;
}
.samm img {
  width: 90%;
}
.worker {
  width: 100%;
  justify-content: space-evenly;
}

.download-comp-container {
  width: 100%;
  border-radius: 15px;
  background: #0066f5;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 400px;
  padding: 0 20px;
  justify-content: space-between;
  gap: 15px;
}
.dow-main-box {
  padding: 0px 80px;
}
.fonsx {
  font-size: 25px;
  color: #fff;
  line-height: 40px;
  font-weight: 600;
}
.instruct {
  font-size: 16px;
  color: #fff;
  margin: 10px 0;
}
/* .download-ri {
  position: absolute;
  right: 5%;
  bottom: -9%;
  margin-left: 20% !important;
} */
/* .download-ri img {
  width: 350px;
} */
.redduma {
  justify-content: space-between;
  display: flex;
  /* width: 50%; */
  padding-right: 50px;
}
.redduma img {
  width: 300px;
  height: 300px;
}

.redddu img {
  width: 370px;
  /* transform: translate(50%); */
}
.left-bx-con {
  width: 50%;
  margin-left: 80px;
}
.app-badge-dow img {
  width: 30%;
  height: 40px;
  margin-top: 20px;
  margin-right: 10px;
}
.qr-ma {
  height: 100% !important;
}
.arr-lf {
  float: left;
  color: #0066f5;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 23px;
}
.new-downlad-box {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 50% !important;
}
.app-badge-download {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 80px;
}
.app-badge-download img {
  width: 150px;
  margin-top: 10px;
  cursor: pointer;
}
.trd-side {
  width: 30%;
}
.trd-side img {
  width: 200px;
}

.resdas img {
  width: 36%;
  transform: translate(90%);
  /* height: 100%; */
}

@media only screen and (max-width: 1150px) {
  .become-ag-cus-container {
    height: 40px;
    width: 50%;
  }
  .left-bx-con {
    width: 50%;
    margin-left: 20px;
  }
  .app-badge-dow img {
    width: 40%;
  }
  .dow-main-box {
    padding: 0px 40px;
  }
  .download-comp-container {
    width: 100%;
    padding: 0 5px;
  }
  /* .download-ri img {
    width: 280px;
  }
  .download-ri {
    bottom: -8%;
  } */
  .redduma img {
    width: 250px;
    height: 250px;
  }
}
@media only screen and (max-width: 940px) {
  .resdas img {
    transform: translate(10%);
    width: 70%;
  }
}
@media only screen and (max-width: 850px) {
  .famh img {
    width: 200px;
  }
  .samm,
  .famh {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .samm img {
    /* margin: 0 auto; */
  }
  .fonsx {
    font-size: 15px;
    line-height: 20px;
  }
  .newm {
    font-size: 13px;
  }
  .app-badge-download {
    font-size: 13px;
  }
}
@media only screen and (max-width: 750px) {
  .become-ag-cus-container {
    width: 82%;
  }
  .inst-works {
    font-size: 13px;
    text-align: center;
    margin: 20px;
  }
  /* .download-ri img {
    width: 240px;
  }
  .download-ri {
    bottom: -6%;
  } */
  .redduma img {
    width: 200px;
    height: 200px;
  }
  .download-comp-container {
    height: 300px;
    padding: 0 20px;
  }
  .dow-main-box {
    padding: 0px 10px;
  }
  .fonsx {
    font-size: 15px;
    line-height: 20px;
  }
  .resdas,
  .redddu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resdas img {
    transform: translate(0%);
    width: 80%;
    /* height: 100%; */
  }
  .trd-side img {
    width: 150px;
  }
  .app-badge-download {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .become-ag-cus-container {
    width: 82%;
  }
  .fonsx {
    font-size: 13px;
    line-height: 20px;
  }
  .instruct {
    font-size: 10px;
    margin: 3px 0;
  }
  /* .download-ri img {
    width: 200px;
  }
  .download-ri {
    bottom: -6%;
  } */
  .redduma {
    padding-right: 20px;
  }
  .redduma img {
    width: 180px;
    height: 180px;
  }
  .download-comp-container {
    height: 250px;
    padding: 0 10px;
  }
}
@media only screen and (max-width: 500px) {
  .become-ag-cus-container {
    width: 100%;
    padding: 5px 10px;
  }
  .agent-custom {
    justify-content: flex-start;
    margin: 40px 8px;
  }
  .become-ae-cus-bx {
    font-size: 12px;
  }
  .fnt {
    font-size: 20px;
    color: #383838;
  }
  .famh {
    width: 50%;
  }
  .works {
    justify-content: center;
  }
  .download-comp-container {
    flex-direction: column;
    padding: 10px;
    height: 100%;
    gap: 15px;
  }
  /* .download-ri {
    display: none;
  } */
  .redduma {
    padding-right: 0px;
  }
  .redduma img {
    width: 100%;
    height: 170px;
  }
  .left-bx-con {
    width: 100%;
    margin-left: 0px;
  }
  .instruct {
    font-size: 13px;
    margin: 3px 0;
  }
  .redddu img {
  transform: translate(0%);

    width: 100%;
    height: 100%;
  }
  .new-downlad-box {
    width: 100% !important;
  }
  .app-badge-download {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-badge-download img {
    width: 150px;
  }
  .trd-side {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .famh {
    width: 70%;
  }
  .app-badge-download {
    font-size: 12px;
  }
}
@media only screen and (max-width: 300px) {
  .famh {
    width: 100%;
  }
}
