.pre-container-bx {
  background: #fff;
  width: 40%;
  height: 100vh;
}
.head-pre {
  font-size: 18px;
  font-weight: 600;
}
.cls-btn {
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #f5f5f5;
  color: #303030;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
}
.ead-cls {
  justify-content: space-between;
}
.pre-add-txt {
  font-size: 14px;
  color: #7c7c7c;
}
.edit-view {
  font-size: 15px;
  border-radius: 1px;
  background: rgba(0, 102, 245, 0.12);
  width: 60px;
  gap: 5px;
  color: #0066f5;
  justify-content: center;
  cursor: pointer;
}
.ed-vi {
  font-size: 12px;
}
.add-pre-edit-view {
  gap: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin: 10px 0;
  /* background: #fff; */
  z-index: 11113;
}
.pre-eit-view-bx {
  padding: 20px;
}
.inpu-lab {
  font-size: 14px;
  margin-bottom: 10px;
  position: relative;
}
.inp-pre {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background: #fff;
  outline: none;
  height: 40px;
  padding: 0 10px;
}
.coll {
  color: #30303070;
}
.form-container-pre {
  padding: 10px;
}
.cat-box-sear {
  font-size: 20px;
  gap: 10px;
  background: #0066f5;
  color: #fff;
  min-width: 30px;
  max-width: 190px;
  padding: 5px;
  border-radius: 4px;
}
.txt-err {
  font-size: 14px;
  cursor: pointer;
}
.cat-sear-pref {
  gap: 6px;
  flex-wrap: wrap;
}
.cat-sear-pref {
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 10px 10px 20px;
}
.per-btn {
  background: #f1f1f1;
  color: #fff;
  border: none;
  width: 100%;
  height: 35px;
  border-radius: 3px;
  margin-top: 15rem;
}
.bbl {
  background: #0066f5;
}

.sus-act-bx {
  color: #ff1d1d;
  gap: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.small-modal-container {
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.14);
  padding: 10px;
  position: absolute;
  z-index: 1111;
  /* width: 100%; */
}
.vate {
  color: #34a853;
}
.delete-modal-container {
  background: #fff;
  width: 30%;
  padding: 10px;
}
.main-del-bxx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% 0;
}
.top-del {
  font-size: 25px;
  justify-content: right;
  display: flex;
  cursor: pointer;
}
.delet-main {
  color: #ff1d1d;
  font-size: 100px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.delt-info {
  font-size: 17px;
  text-align: center;
}
.btn-del {
  width: 60%;
  background: #ff1d1d;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}
.btn-del-bx {
  justify-content: center;
  margin: 30px 0;
}
.view-dit {
  font-size: 15px;
  gap: 6px;
  cursor: pointer;
}
.ed-vid {
  font-size: 17px;
}
.dwn {
  margin-top: 80px;
}
.each-bx-loc {
  justify-content: space-between;
  margin-bottom: 5px;
}
.loca-modal-container {
  border-radius: 0px 0px 2px 2px;
  background: #f7f7f7;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 10px;
  position: absolute;
  top: 100%;
  z-index: 11;
  width: 100%;
  height: 400px;
}
.red-wd {
  width: 130px;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}
.list-items-box {
  height: 80%;
  overflow-y: scroll;
  scrollbar-width: normal;
}
.list-items-box::-webkit-scrollbar {
  width: 0.1vw;
}
.preview-det {
  font-size: 18px;
  font-weight: 500;
  color: #7c7c7c;
  gap: 20px;
  display: flex;
  align-items: center;
}
.inp-preview {
  outline: none;
  border: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 40px; */
  height: 30px;
  font-size: 16px;
}
.inp-preview::placeholder {
  color: #000;
  font-size: 16px;
}
.jus-btw {
  gap: 20px;
  margin-bottom: 5px;
}
.form-container-pre table {
  width: 100%;
}
.form-container-pre td {
  width: 50%;
}
.point {
  cursor: pointer;
}
.person-det {
  font-size: 16px;
  font-weight: 500;
}
.inpu-sm-bx label {
  margin-bottom: 5px;
}
.mage-ct {
  color: #7c7c7c;
  font-size: 12px;
}
.inpu-pic {
  border-radius: 0px 3px 3px 0px;
  background: #f1f1f1;
  justify-content: center;
  width: 100px;
  height: 40px;
  color: #7c7c7c;
  cursor: pointer;
  margin-top: 3px;
}
.ndn {
  margin-top: -20px;
}
.pers-cont-bx {
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: normal;
}
.pers-cont-bx::-webkit-scrollbar {
  width: 0.2vw;
  /* background: #0066f5;
  border-radius: 20px; */
}
.red-margn {
  margin-top: 10px;
}
.vi-edi-pre-det {
  justify-content: flex-end;
  height: 100vh;
}
.view-det td {
  display: flex;
}
.admin-header {
  font-size: 30px;
  font-weight: 600;
  border-bottom: 0.8px solid #d9d9d9;
  background: #fff;
  padding: 10px;
  margin-bottom: 30px;
}
.no-padd {
  padding: 0;
}
.list-all-adm {
  padding: 15px;
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: normal;
}
.list-all-adm::-webkit-scrollbar {
  width: 0.1vw;
}
.admin-main-bx {
  background: #f7f7f7;
  position: relative;
  height: 100vh;
}
.cent-jxt {
  justify-content: center;
}
.cls-save-btn {
  width: 80px;
  border-radius: 4px;
  border: 1px solid #000e23;
  color: #000e23;
  font-size: 16px;
  justify-content: center;
  font-weight: 600;
  height: 30px;
  cursor: pointer;
}
.just-btw {
  justify-content: space-between;
}
.bottom-adm-bx {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 0.8px solid #d9d9d9;
  background: #fff;
  padding: 15px 15px 30px;
}
.add-admin-cont {
  border-radius: 4px;
  background: #fff;
  width: 70%;
  position: absolute;
  top: 40%;
  left: 10%;
  padding: 10px;
  z-index: 1234;
}
.all-inp-admin-bx {
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background: #fff;
  height: 45px;
  gap: 5px;
  padding: 0 10px;
  flex-wrap: wrap;
}
.adm-add-inp {
  height: 28px;
  outline: none;
  border: none;
  font-size: 14px;
}
.cls-eml {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}
.input-email {
  font-size: 13px;
  color: #7c7c7c;
  border-radius: 2px;
  border: 0.8px solid #0066f5;
  background: #fff;
  padding: 0 3px;
  gap: 6px;
}
.mj-tb {
  margin: 20px 0 30px;
}
.app-icon {
  font-size: 25px;
  cursor: pointer;
}
.dulll {
  background: #e9e9e9;
}
.invalid-email {
  border: 1px solid #ff1d1d;
}
.icon-ddot-bx {
  position: relative;
}
.admin-small-md {
  position: absolute;
  left: -150px;
  z-index: 1;
  background: yellowgreen;
}
.cincr {
  width: 180px;
}
.adm-ttst {
  font-size: 14px;
}
.adm-smm {
  font-size: 20px;
}
.chg {
  color: #000;
}
.admin-right-container {
  background: #00000064;
  width: 40%;
  height: 100%;
  padding: 20px 15px;
}
.am-profil {
  gap: 15px;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  font-size: 16px;
}
.am-profil img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.admin-rigt-box {
  background: #fff;
  border-radius: 5px;
  width: 70%;
  padding-bottom: 20px;
}
.top-adm-rit {
  border-bottom: 0.8px solid #d9d9d9;
  background: #0066f5;
  color: #fff;
  font-size: 18px;
  gap: 20px;
  padding: 5px;
  border-radius: 5px 5px 0 0px;
}
.perm-txt {
  font-size: 17px;
  font-weight: 600;
  color: #0066f5;
  margin: 5px;
}

/* SwitchToggle.css */
.toggle-container {
  display: flex;
  align-items: center;
  margin: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff1d1d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: none;
}

.slider:before {
  font-family: "Font Awesome 6 free";
  position: absolute;
  content: "\f00d";
  height: 30px;
  width: 30px;
  left: -1px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #34a853;
}

input:focus + .slider {
  box-shadow: 0 0 1px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  content: "\f00d";
  font-size: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid #ff1d1d;
  color: #ff1d1d;
}
.slider.round.checked:before {
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  content: "\f00c";
  font-size: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #34a853;
  border: 2px solid #34a853;
  margin-left: 8px;
}
.contro-title-bx {
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 5px;
  font-size: 15px;
}
.sv-btn {
  width: 30%;
  border: none;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #0066f5;
  height: 35px;
  font-size: 16px;
}
.save-btn {
  margin: 15px auto;
  display: flex;
  justify-content: center;
}
.backcls {
  cursor: pointer;
}
.log-modal-container {
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  width: 200px;
  padding: 15px 10px;
  border-radius: 10px;
  border: 1px solid #0066f5;
}
.lo-out-btn {
  background: #0066f5;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin: 15px 0;
}
.cls-cn {
  cursor: pointer;
}
.red-tj {
  max-height: 200px;
}
.max-fd {
  max-height: 300px;
}
